import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { mixPanelEvent } from '@features/activities/data';
import { getCurrentLocaleCode } from '@mongez/localization';
import { TranslateModule } from '@ngx-translate/core';
import { parseError } from '@presentation/shared/error';
import { toastError } from '@presentation/shared/toast';
import {
  DynamicIncentiveProgramModel,
  LevelsData,
  OrderProgressData,
} from '../../../../../core/domain/dynamic-incentive-program.model';
import { AcceptDynamicIncentiveProgramUseCase } from '../../../../../core/usecases/dynamic-incentive-program/accept-dynamic-incentive-program.usecase';
import { DeclineDynamicIncentiveProgramUseCase } from '../../../../../core/usecases/dynamic-incentive-program/decline-dynamic-incentive-program.usecase';
import { appUrlsConstantsInjectionToken } from '../../../../../data/injection-tokens/app-urls-constants.injection-token';
import { LocalizedComponent } from '../../../../base/localized.component';
import { CountdownTimerPipe } from '../../../../shared/pipes/countdown-timer.pipe';
import { CurrencyShortNamePipe } from '../../../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../../../shared/pipes/currency-translate.pipe';
import { MultitenancyService } from '../../../../shared/services/multitenancy.service';
import { calculateDateDifference } from '../../../../shared/utilities/caluclate-date-difference.utility';
import { DateWrapperUtility } from '../../../../shared/utilities/date-wrapper.utility';
import { SubmitFeedbackFormComponent } from '../../dynamic-incentive-program/submit-feedback-form/submit-feedback-form.component';

@Component({
  selector: 'app-incentive-program-challenge-card-action',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatProgressBarModule,
    CommonModule,
    NgFor,
    TranslateModule,
    CountdownTimerPipe,
    CurrencyShortNamePipe,
    CurrencyTranslatePipe,
  ],
  templateUrl: './incentive-program-challenge-card-action.component.html',
  styleUrls: ['./incentive-program-challenge-card-action.component.scss'],
})
export class IncentiveProgramChallengeCardActionComponent
  extends LocalizedComponent
  implements OnInit
{
  @Input() incentiveProgramData: DynamicIncentiveProgramModel;

  @Input() shouldOnlyDisplayIncentiveWidget = false;

  @Output() actionClicked: EventEmitter<void> = new EventEmitter();

  public assetsPath = 'assets/img/dynamic-incentive/';

  public currency: string;

  public dynamicIncentiveData: DynamicIncentiveProgramModel;

  public dynamicLevels: LevelsData;

  public orderProgressData: OrderProgressData;

  public programStatus: string;

  public termsAndConditions: string[];

  public incentiveProgramName: string;

  public loadingProgress: number;

  public acceptedDate: string;

  public durationInDays: number;

  public remainingDurationInHours: number;

  public endDateCountDownTimer: number;

  public extraTimeCountDownTimer: number;

  public expiryDateCountDownTimer: number;

  public extraTimeInDays: number;

  public isButtonsDisabled = false;

  constructor(
    private _acceptDynamicIncentiveProgramUseCase: AcceptDynamicIncentiveProgramUseCase,
    private _declineDynamicIncentiveProgramUseCase: DeclineDynamicIncentiveProgramUseCase,
    private _multitenancyService: MultitenancyService,
    private _dialog: MatDialog,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    super();
  }

  ngOnInit(): void {
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this.getDynamicIncentiveProgram();
  }

  getDynamicIncentiveProgram(): void {
    this.dynamicIncentiveData = this.incentiveProgramData;
    [this.dynamicLevels] = this.dynamicIncentiveData.levels;
    this.orderProgressData = this.dynamicIncentiveData.ordersProgressResult;
    this.programStatus = this.dynamicIncentiveData.status;
    this.incentiveProgramName = this.dynamicIncentiveData.name.ar;
    this.termsAndConditions = this.dynamicIncentiveData.termsAndConditions
      .map((terms) => (this.isLTR ? terms.en : terms.ar))
      .filter((term) => term !== undefined) as string[];
    this.durationInDays = Math.floor(this.dynamicIncentiveData.durationInHours / 24);
    this.remainingDurationInHours =
      this.dynamicIncentiveData.durationInHours - this.durationInDays * 24;
    this.extraTimeInDays = Math.round(
      calculateDateDifference(
        this.dynamicIncentiveData.extraTime,
        this.dynamicIncentiveData.endDate,
      ) /
        (1000 * 3600 * 24),
    );
    const acceptanceDate = this.dynamicIncentiveData.startDate.toDateString();
    this.acceptedDate = this.calculateCurrentFormattedDate(acceptanceDate);
    this.countdownDate();
    this.calculateLoadingProgress();
  }

  calculateCurrentFormattedDate(date: string): string {
    const selectedLanguage = getCurrentLocaleCode();
    const { year, month, day } = DateWrapperUtility.getFormattedDateParts(
      date,
      { day: 'D', month: 'MMM', year: 'YYYY' },
      selectedLanguage,
    );
    return `${day} ${month} ${year}`;
  }

  countdownDate(): void {
    const checkDates = (): void => {
      const { expiryDate } = this.dynamicIncentiveData;
      const { endDate } = this.dynamicIncentiveData;
      const { extraTime } = this.dynamicIncentiveData;
      const currentDate = new Date();
      if (expiryDate) {
        this.expiryDateCountDownTimer = calculateDateDifference(expiryDate, currentDate);
      }
      this.endDateCountDownTimer = calculateDateDifference(endDate, currentDate);
      this.extraTimeCountDownTimer = calculateDateDifference(extraTime, currentDate);

      if (this.expiryDateCountDownTimer <= 0) {
        this.expiryDateCountDownTimer = 0;
        this.isButtonsDisabled = true;
        this.programStatus = 'EXPIRED';
      }
      this.endDateCountDownTimer = this.endDateCountDownTimer > 0 ? this.endDateCountDownTimer : 0;
      this.extraTimeCountDownTimer =
        this.extraTimeCountDownTimer > 0 ? this.extraTimeCountDownTimer : 0;
    };
    setInterval(() => {
      checkDates();
    }, 1000);
    checkDates();
  }

  onDeclineClicked(): void {
    const challengeBusinessId = this.dynamicIncentiveData.merchantChallengeBusinessId;
    this._declineDynamicIncentiveProgramUseCase.execute(challengeBusinessId).subscribe({
      next: () => {
        this.actionClicked.emit();
        mixPanelEvent('dynamic_incentive_challenge_rejected');
      },
      error: (error) => {
        toastError(parseError(error));
      },
    });
  }

  onAcceptClicked(): void {
    const challengeBusinessId = this.dynamicIncentiveData.merchantChallengeBusinessId;
    this._acceptDynamicIncentiveProgramUseCase.execute(challengeBusinessId).subscribe({
      next: () => {
        this.actionClicked.emit();
        mixPanelEvent('dynamic_incentive_challenge_accepted');
      },
      error: (error) => {
        toastError(parseError(error));
      },
    });
  }

  onFeedbackClicked(): void {
    mixPanelEvent('dynamic_incentive_feedback_clicked');
    this._dialog.open(SubmitFeedbackFormComponent, {
      width: '700px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: this.dynamicIncentiveData.merchantChallengeBusinessId,
    });
  }

  calculateLoadingProgress(): void {
    const ordersPerLevel = this.dynamicLevels.numOfOrders;
    const confirmedOrders = this.orderProgressData?.confirmedOrders;
    const deliveredOrders = this.orderProgressData?.deliveredOrders;
    const receivedOrders = this.orderProgressData?.totalPlacedOrders;
    const prepaidOrders = this.orderProgressData?.prePaidOrdersCount;
    if (this.dynamicIncentiveData.orderType === 'confirmed') {
      this.loadingProgress = (confirmedOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'delivered') {
      this.loadingProgress = (deliveredOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'received') {
      this.loadingProgress = (receivedOrders / ordersPerLevel) * 100;
    } else if (this.dynamicIncentiveData.orderType === 'prepaid') {
      this.loadingProgress = (prepaidOrders / ordersPerLevel) * 100;
    }
  }

  goToProduct(): void {
    this._router.navigate([this._appURLs.PRODUCTS_V2_URL, this.dynamicIncentiveData.productId]);
  }
}
